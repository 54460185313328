import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import ProductViewer from '@components/products/product-viewer';
import Product_viewer_02 from '@components/products/product_viewer_02';
import Product_viewer_03 from '@components/products/product_viewer_03';
import Product_viewer_04 from '@components/products/product_viewer_04';
import Product_viewer_assembly from '@components/products/product_viewer_assembly';

import Hero from '@components/common/hero';
import PageIntro from '@components/common/page-intro';
import ContactIntro from '@components/common/contact-intro';

import Hero__mds from '@img/svg/products/hero__mds.svg';
import Img from 'gatsby-image';

import Layout from '@components/common/layout';
import src from './product-list';
import { color } from '@src/settings';

const ProductsPage = ({ data }) => {
	return (
	<PageCont baseBgColor={ color.paleGray }>
		<Hero
			img={ data["hero_img_200414_2"] }
			title={<Hero__mds />}
			subTitle="製品紹介"
		/>
		<PageIntro
			title={<>車体のあらゆるパーツを製造可能</>}
			subtitle={<>外板部品、シェルボディ部品、アンダー部品、アッセンブリ部品、精密機械加工部品まで、サイズも形状も様々な自動車の車体製造を行っています。ご要望に合わせた、オーダメイドの設計・製造も承ります。</>}
		/>
		<ProductViewer
			className="productViewer"
			title="ボディーパーツ"
			subtitle="BODY PARTS"
			lead={<>高い強度で衝撃から乗員を守り、車体構造を支える部品群です</>}
			listNum={ 0 }
		/>
		<ProductViewer
			className="productViewer"
			title="ボディーパーツ"
			subtitle="BODY PARTS"
			lead={<>高い強度で衝撃から乗員を守り、正確なボディー精度で性能を支える部品群です</>}
			listNum={ 1 }
		/>
		<ProductViewer
			className="productViewer"
			title="リアアンダーパーツ"
			subtitle="REAR UNDER PARTS"
			lead={<>安全性と乗員の乗り心地を支える基盤であるフレームを構成する部品群です</>}
			listNum={ 2 }
		/>
		<ProductViewer
			className="productViewer"
			title="アッセンブリ部品"
			subtitle="ASSEMBLY PARTS"
			lead={<>機能部品を取り付け、車体の動作を支える重要な部品群です</>}
			listNum={ 3 }
		/>
		<ProductViewer
			className="productViewer"
			title="外板部品"
			subtitle="OUTER PARTS"
			lead={<>車体の美しいスタイルを生み出す外板部品群です</>}
			listNum={ 4 }
		/>
		<ContactIntro />
	</PageCont>
	)
}


const PageCont = styled(Layout)`
	.productViewer{
		border-top: 1px solid #cdd1d6;
		border-bottom: 1px solid #cdd1d6;
	}
	.productViewer + .productViewer{
		border-top: none;
	}
`

export default ProductsPage

export const query = graphql`
	query ProductsImgs{
		hero_img_200414_2: file( relativePath: { eq: "products/hero-img_200414_2.jpg" }){
			...imgdata
		},
	},
	fragment imgdata on File{
	  childImageSharp {
	    fluid(quality: 90, maxWidth: 950) {
				...GatsbyImageSharpFluid_withWebp 
	    }
	  }
	}
`
